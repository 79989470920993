import { render, staticRenderFns } from "./Loading.vue?vue&type=template&id=6408fe52&scoped=true&"
import script from "./Loading.vue?vue&type=script&lang=js&"
export * from "./Loading.vue?vue&type=script&lang=js&"
import style0 from "./Loading.vue?vue&type=style&index=0&id=6408fe52&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.9.8_css-loader@6.11.0_vue-template-compiler@2.7.16_webpack@5.92.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6408fe52",
  null
  
)

export default component.exports