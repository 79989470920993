<template>
  <section class="main">
    <div class="container pt-5 pb-5">
      <div class="row">
        
      </div>
    </div>
  </section>
</template>
<script>
import LineChart from "../../components/LineChart";
export default {
  name: "Homeprofessor",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    LineChart,
  },
  data() {
    return {
       datacollection: null,
      chart: "",
      value: "",
      loading: false,
      cursos: [],
      faturas: [],
      totalVendido: 0,
      totalVendidocompleto: 0,
      totalAlunos: 0,
      totalAlunosMes: 0,
      totalAreceber:0
    };
  },
  computed: {
    group() {
      return this.$store.state.user.group;
    },
  },

  methods: {
    fillData() {
      this.datacollection = {
        labels: [this.getRandomInt(), this.getRandomInt()],
        datasets: [
          {
            label: "Data One",
            backgroundColor: "#f87979",
            data: [this.getRandomInt(), this.getRandomInt()],
          },
          {
            label: "Data One",
            backgroundColor: "#f87979",
            data: [this.getRandomInt(), this.getRandomInt()],
          },
        ],
      };
    },
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5;
    },
    async getAlunos() {
      this.$http
        .get("/api/gestor/relatorios")
        .then((response) => 
        {
          var responseData =  response.data;
          this.totalAlunos = responseData["totalAlunos"];
          this.totalAlunosMes =  responseData["totalAlunosMes"];
        })
        .catch(() => {});
    },
    async getFaturas() {
      this.$http
        .get("/api/gestor/faturasgestor")
        .then((response) => 
        {
          var responseData =  response.data;
          this.totalCertificados = response.data.totalCertificados;
          this.totalAlunos = responseData["alunos"];
          this.totalAreceber = responseData["mes"];//PENDERE A RECEBER MÊS ATUAL
          this.totalVendidocompleto = responseData["all"];//TOTAL RECEBIDO
        })
        .catch(() => {});
    },
    getCursoDestaque() {
      this.loading = true;
      this.$http.get("/api/gestor/get/cursos/").then((response) => {
        this.cursos = response.data.entries;
        this.loading = false;
      });
    },
  },
  filters: {
    currency(valor) {
      var formatter = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
      return formatter.format(valor);
    },
  },
  mounted() {
      //  this.fillData()
    document.title = this.$titleSite;
    this.getCursoDestaque();
    
    if(this.$wlType === 'pdv'){
      this.getFaturas();
    }
    if(this.$wlType === 'uc'){
      this.getAlunos();
    }
  },
};
</script>
<style lang="scss" scoped>
.card {
  position: relative;
  min-height: 140px;
}
</style>