import { render, staticRenderFns } from "./MeuPerfil.vue?vue&type=template&id=e05d4272&scoped=true&"
import script from "./MeuPerfil.vue?vue&type=script&lang=js&"
export * from "./MeuPerfil.vue?vue&type=script&lang=js&"
import style0 from "./MeuPerfil.vue?vue&type=style&index=0&id=e05d4272&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.9.8_css-loader@6.11.0_vue-template-compiler@2.7.16_webpack@5.92.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e05d4272",
  null
  
)

export default component.exports