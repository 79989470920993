<template>
  <fragment>
    <div class="row pt-5 category-list">
      <div class="col pt-3">
        <span class="badge badge-info shadow-sm" v-bind:class="BindOrderStatus(undefined)"
          @click="setCategory(undefined)">Todos</span>
        <br>
        <router-link :to="{ name: 'meuscursos' }" class="btn btn-sm btn-primary shadow-sm mt-3 my_link">Continue seus
          cursos</router-link>

      </div>

      <div class="col pt-3" v-for="cat in categorias">
        <div class="text-center" @click="setCategory(cat.slug)" v-if="cat.icone != undefined">
          <img :src="$apiURL + cat.icone"
            style="width: 90px;background-color: var(--primary);border-radius: 50%;padding: 10px;cursor: pointer;border: 2px solid #0e0e0e;">
          <br>
          <small style=" font-size: 9pt; ">{{ cat.nome }}</small>
        </div>
        <div class="text-center btn btn-sm btn-primary shadow-sm mt-3 my_link" @click="setCategory(cat.slug)" v-else>
          {{ cat.nome }}
        </div>
      </div>

    </div>

    <transition-group mode="out-in" tag="div" class="row" v-if="cursos.length >= 0">
      <div class="col-md-4 pt-4" v-for="(curso, index) in cursos" :key="`key-${index}`"
        v-show="Visible(curso.title, curso.description)">
        <router-link :to="{ name: 'curso', params: { id: curso.slug } }">
          <div class="card product shadow-sm border-0 d-flex justify-content-start">
            <!-- <div class="card-header"></div> -->
            <!--<div class="card-header" :style="'background: url(' + $storage + curso.capa + ');'"></div>-->
            <div class="card-header" :style="'background: url(' + curso.cover_url + ');'"></div>
            <div class="card-body">
              <div class="d-flex-it d-flex">
                <p class="small secondary">{{ curso.category.nome }}</p>
                <p class="small primary" v-if="curso.ponto">Ganhe <strong>+{{ curso.ponto }}</strong> pontos</p>
              </div>
              <div class="d-flex justify-content-between align-items-center pb-0">
                <p class="d-flex align-items-center">
                  <Rating class="pt-2" v-model="curso.total" :cancel="false" :disabled="true" />
                </p>
              </div>

              <p class="card-title mb-2">{{ curso.title }}</p>
              <small class="text-muted">{{ !curso.description ? 'Uma breve descrição do curso' : curso.description }}</small>
            </div>
            <div class="card-footer border-0 bg-white pb-0">
              <div class="d-flex justify-content-between align-items-center pb-0">
                <p class="d-flex flex-column align-items-start small" v-if="$wlType === 'pdv'">
                  <small class="danger preco-promo"
                    v-if="parseInt(curso.promotional_value) > 0 || parseInt(curso.value) > 0">{{ curso.value |
                      currency }}</small>
                  <span class="lead" v-if="parseInt(curso.promotional_value) > 0">{{ curso.promotional_value |
                    currency }}</span>
                  <span class="lead" v-else>Curso Grátis</span>
                </p>

                <p class="d-flex align-items-center small" v-if="$wlType === 'uc'">
                  <i class="material-icons tertiary">list</i> {{ curso.modules.length }} módulos
                </p>
                <p class="d-flex align-items-center small">
                  <i class="material-icons tertiary">schedule</i>
                  {{ curso.workload }}
                </p>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </transition-group>
    <!--
    <div v-else>
      <div>
        <loading color="var(--secondary)" />
      </div>
    </div>
  -->
    <transition-group mode="out-in" tag="div" class="row" v-if="newcourses.length >= 0">
      <div class="col-md-4 pt-4" v-for="(newcurso, index) in newcourses" :key="`key-${index}`"
        v-show="Visible(newcurso.title, newcurso.description)">
        <router-link :to="{ name: 'curso', params: { id: newcurso.slug } }">
          <div class="card product shadow-sm border-0 d-flex justify-content-start">
            <!-- <div class="card-header"></div> -->
            <!--<div class="card-header" :style="'background: url(' + $storage + curso.capa + ');'"></div>-->
            <div class="card-header" :style="'background: url(' + newcurso.cover_url + ');'"></div>
            <div class="card-body">
              <div class="d-flex-it d-flex">
                <p class="small secondary">{{ newcurso.categoria.nome }}</p>
                <p class="small primary" v-if="newcurso.ponto">Ganhe <strong>+{{ newcurso.ponto }}</strong> pontos</p>
              </div>
              <div class="d-flex justify-content-between align-items-center pb-0">
                <p class="d-flex align-items-center">
                  <Rating class="pt-2" v-model="newcurso.total" :cancel="false" :disabled="true" />
                </p>
              </div>

              <p class="card-title mb-2">{{ newcurso.titulo }}</p>
              <small class="text-muted">{{ !newcurso.descricao ? 'Uma breve descrição do curso' : newcurso.descricao }}</small>
            </div>
            <div class="card-footer border-0 bg-white pb-0">
              <div class="d-flex justify-content-between align-items-center pb-0">
                <p class="d-flex flex-column align-items-start small" v-if="$wlType === 'pdv'">
                  <small class="danger preco-promo"
                    v-if="parseInt(newcurso.promotional_value) > 0 || parseInt(newcurso.value) > 0">{{ newcurso.value |
                      currency }}</small>
                  <span class="lead" v-if="parseInt(newcurso.promotional_value) > 0">{{ newcurso.promotional_value |
                    currency }}</span>
                  <span class="lead" v-else>Curso Grátis</span>
                </p>

                <p class="d-flex align-items-center small" v-if="$wlType === 'uc'">
                  <i class="material-icons tertiary">list</i> {{ newcurso.modulos.length }} módulos
                </p>
                <p class="d-flex align-items-center small">
                  <i class="material-icons tertiary">schedule</i>
                  {{ newcurso.carga }}
                </p>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </transition-group>
    <div v-if="loadCurso == true">
      <loading color="var(--secondary)" />
    </div>
    <div class="row d-flex justify-content-center align-items-center pt-3" v-else>
      <div class="card border-0">
        <div class="card-body">
          <h5 class="d-flex justify-content-center align-items-center">
            <i class="material-icons pr-2 py-3 blue tertiary">info</i> Não tem mais cursos.
          </h5>
        </div>
      </div>
    </div>
  </fragment>
</template>

<script>
import Rating from "primevue/rating";
export default {
  name: "Cursos",
  components: { Rating },
  props: {
    role: String,
  },
  data() {
    return {
      search: null,
      categoria: undefined,
      cursos: [],
      cursosAluno: [],
      page: 0,
      scro: true,
      loadCurso: true,
      Mycursos: [],
      categories: [],
      slug: '',
      DataCategory: [],
      DataCourse: [],
      categorias: [],
      newcourses: []
    };
  },
  filters: {
    currency(valor) {
      var formatter = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
      return formatter.format(valor);
    },
  },
  created() {
    if (this.$route.query.buscar !== undefined && this.$route.query.buscar !== null) {
      this.search = this.$route.query.buscar;
    } else {
      this.search = null;
    }
  },
  methods: {
    getCategorias() {
      this.$http.get("/api/aluno/get/categorias").then((response) => {
        this.categorias = response.data.entries;
      });
    },
    async getCursosExtern() {
      this.newcourses = [];
      this.$http.get(`/api/aluno/newcourses`).then((response) => {
        
        if (this.categoria == undefined) {
          this.newcourses = response.data;
        }else{
          this.newcourses = response.data.filter(cours => cours.newcategoria.slug == this.categoria || cours.categoria.slug == this.categoria);
          this.ty = 'all';
          this.type = 'my';
        }
        this.loadCurso = false;
      })
        .catch(error => {
          console.log(error)
        })
    },
    Visible(title, description) {
      if (this.search != undefined || this.search != null) {
        if (
          title.toLowerCase().includes(this.search.toLowerCase()) ||
          description.toLowerCase().includes(this.search.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    async getCursos() {
      this.page = 0;
      this.cursos = [];
      const headers = { "wlToken": this.$wlToken };
      var paramsList;
      if (this.categoria == undefined) {
        paramsList = { limit: 100/*limit: 9*/, offset: this.page };
      } else {
        paramsList = { category: this.categoria, limit: 9, offset: this.page };
      }
      var params = '?' + new URLSearchParams(paramsList);
      fetch(this.$apiHq + "/course/list" + params, { headers })
        .then(response => response.json())
        .then(data => {
          if (data.length > 0) {
            if (this.Mycursos.length > 0) {
              if (this.cursos.length == 0) {
                data.filter(result => {
                  //removendo meus cursos
                  if (this.Mycursos.findIndex(Mycurso => Mycurso._id === result.ref_id) < 0) {
                    //removendo cursos que já imprimiram
                    if (result.ref_token != this.$wlToken) {
                      if (this.DataCategory.length > 0 || this.DataCourse.length > 0) {
                        if (this.DataCategory.findIndex(dataCat => dataCat.slug === result.category.slug) < 0) {
                          if (this.DataCourse.findIndex(Dcour => Dcour.curso_slug === result.slug) < 0) {
                            this.cursos.push(result);
                          }
                        }
                      } else {
                        this.cursos.push(result);
                      }
                    } else {
                      this.cursos.push(result);
                    }
                  }
                });
                if (this.cursos.length == 0) {
                  this.scro = false;
                  //this.loadCurso = false;
                }
              } else {
                var Dt = data.filter(result => {
                  //removendo meus cursos
                  if (this.Mycursos.findIndex(Mycurso => Mycurso._id === result.ref_id) < 0) {
                    //removendo cursos que já imprimiram
                    if (result.ref_token != this.$wlToken) {
                      if (this.DataCategory.length > 0 || this.DataCourse.length > 0) {
                        if (this.DataCategory.findIndex(dataCat => dataCat.slug === result.category.slug) < 0) {
                          if (this.DataCourse.findIndex(Dcour => Dcour.curso_slug === result.slug) < 0) {
                            this.cursos.push(result);
                          }
                        }
                      } else {
                        this.cursos.push(result);
                      }
                    } else {
                      this.cursos.push(result);
                    }
                  }
                });
                if (Dt.length == 0) {
                  this.scro = false;
                  //this.loadCurso = false;
                }
              }
            } else {
              data.filter(result => {
                if (result.ref_token != this.$wlToken) {
                  if (this.DataCategory.length > 0 || this.DataCourse.length > 0) {
                    if (this.DataCategory.findIndex(dataCat => dataCat.slug === result.category.slug) < 0) {
                      if (this.DataCourse.findIndex(Dcour => Dcour.curso_slug === result.slug) < 0) {
                        this.cursos.push(result);
                      }
                    }
                  } else {
                    this.cursos.push(result);
                  }
                } else {
                  this.cursos.push(result);
                }
              })
            }
            this.scro = true;
            if (data.length < 9) {
              //this.loadCurso = false;
            }
          } else {
            this.scro = false;
            //this.loadCurso = false;
          }
        });
    },
    BindOrderStatus: function (slug) {
      //if(slug == this.$route.query.categoria)
      if (slug == this.categoria) {
        return " active disabled'";
      }
    },
    setCategory(slug) {
      this.cursos = [];
      this.categoria = slug;
      this.page = 0;
      this.scro = true;
      this.loadCurso = true;
      this.getCursos();
      this.getCursosExtern();
    },
    /*
    async getCategorias() {
    this.categories = [];
      const headers = { "wlToken": this.$wlToken };
      fetch(this.$apiHq+"/category/list", { headers })
      .then(response => response.json())
      .then(data =>{
        if(data.length > 0){
          data = data.sort(function(a,b) {
            return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
          });
            var anl = true; 
            if(this.DataCategory.length > 0){
            data.filter(result => {
              
              if(result.ids.length > 0){
                  anl = true;
                  for(var e=0; e < result.ids.length; e++){
                      if(result.ids[e].ref_token == this.$wlToken){
                        anl = false;
                      }
                  }
              }
              if(anl){
                //retur categorias com status true;
                if(this.DataCategory.findIndex(dataCat => dataCat.slug === result.slug) < 0){                    
                  this.categories.push(result)
                }
              }else{
                this.categories.push(result)
              }
            });
            }else{
              this.categories = data;
            }
        }
      }).catch(() => {
      });
    },*/
    async getCategoriasRemov() {
      this.DataCategory = [];
      this.$http
        .get("/api/user/actionCategory")
        .then((response) => {
          this.DataCategory = response.data;
          this.getCategorias();
        }).catch(() => {
          this.getCategorias();
        });
    },

    async getCursosRemov() {
      this.DataCourse = [];
      this.$http
        .get("/api/user/actionCourse")
        .then((response) => {
          this.DataCourse = response.data;
          this.getCursos();
        }).catch(() => {
          this.getCursos();
          //this.loadCurso = false;
        });
    },
    handleScroll: function () {
      if (this.scro == true) {
        if (document.documentElement.scrollTop >= (document.documentElement.scrollHeight - (document.documentElement.scrollHeight * 70) / 100) &&
          document.documentElement.scrollTop > 450) {
          this.page += 9;
          this.scro = false;
          const headers = { "wlToken": this.$wlToken };
          var paramsList;
          if (this.categoria == undefined) {
            paramsList = { limit: 9, offset: this.page };
          } else {
            paramsList = { category: this.categoria, limit: 9, offset: this.page };
          }
          var params = '?' + new URLSearchParams(paramsList);
          fetch(this.$apiHq + "/course/list" + params, { headers })
            .then(response => response.json())
            .then(data => {
              if (data.length > 0) {
                if (this.Mycursos.length > 0) {
                  if (this.cursos.length == 0) {
                    data.filter(result => {
                      //removendo meus cursos
                      if (this.Mycursos.findIndex(Mycurso => Mycurso._id === result.ref_id) < 0) {
                        //removendo cursos que já imprimiram
                        if (result.ref_token != this.$wlToken) {
                          if (this.DataCategory.length > 0 || this.DataCourse.length > 0) {
                            if (this.DataCategory.findIndex(dataCat => dataCat.slug === result.category.slug) < 0) {
                              if (this.DataCourse.findIndex(Dcour => Dcour.curso_slug === result.slug) < 0) {
                                this.cursos.push(result);
                              }
                            }
                          } else {
                            this.cursos.push(result);
                          }
                        } else {
                          this.cursos.push(result);
                        }
                      }
                    });
                    if (this.cursos.length == 0) {
                      this.scro = false;
                      //this.loadCurso = false;
                    }
                  } else {
                    var Dt = data.filter(result => {
                      //removendo meus cursos
                      if (this.Mycursos.findIndex(Mycurso => Mycurso._id === result.ref_id) < 0) {
                        //removendo cursos que já imprimiram
                        if (result.ref_token != this.$wlToken) {
                          if (this.DataCategory.length > 0 || this.DataCourse.length > 0) {
                            if (this.DataCategory.findIndex(dataCat => dataCat.slug === result.category.slug) < 0) {
                              if (this.DataCourse.findIndex(Dcour => Dcour.curso_slug === result.slug) < 0) {
                                this.cursos.push(result);
                              }
                            }
                          } else {
                            this.cursos.push(result);
                          }
                        } else {
                          this.cursos.push(result);
                        }
                      }
                    });
                    if (Dt.length == 0) {
                      this.scro = false;
                      //this.loadCurso = false;
                    }
                  }
                } else {
                  data.filter(result => {
                    if (result.ref_token != this.$wlToken) {
                      if (this.DataCategory.length > 0 || this.DataCourse.length > 0) {
                        if (this.DataCategory.findIndex(dataCat => dataCat.slug === result.category.slug) < 0) {
                          if (this.DataCourse.findIndex(Dcour => Dcour.curso_slug === result.slug) < 0) {
                            this.cursos.push(result);
                          }
                        }
                      } else {
                        this.cursos.push(result);
                      }
                    } else {
                      this.cursos.push(result);
                    }
                  })
                }
                this.scro = true;
                if (data.length < 9) {
                  //this.loadCurso = false;
                }
              } else {
                this.scro = false;
                //this.loadCurso = false;
              }
            });
        }
      }
    }
  },
  mounted() {
    if (this.$store.state.user.cursos?.length > 0) {
      for (var i = 0; i < this.$store.state.user.cursos.length; i++) {
        if (typeof this.$store.state.user.cursos[i]['_id']['$oid'] != "undefined") {
          this.Mycursos.push({
            _id: this.$store.state.user.cursos[i]['_id']['$oid'],
          });
        } else {
          this.Mycursos.push({
            _id: this.$store.state.user.cursos[i]['_id'],
          });
        }
      }
    }
    this.getCategoriasRemov();
    this.getCursosRemov();
    window.addEventListener('scroll', this.handleScroll);

    this.getCategorias();
    this.getCursosExtern();
  },
  destroyed: function () {
    window.removeEventListener('scroll', this.handleScroll);
  }
};
</script>
<style lang="scss" scoped>
.my_link {
  white-space: nowrap;
  background-color: var(--primary);
  border-radius: 2rem;
  font-size: 12pt;
}

.d-flex-it {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 900px) {
  .category-list {
    width: 100vw;
    overflow-y: hidden;
    white-space: nowrap;
    flex-direction: row;
    flex-wrap: nowrap;
  }
}

.category-list {
  .badge {
    min-width: 100px;
    padding: 0.5rem 1rem;
    border-radius: 2rem;
    background: var(--white);
    color: var(--dark-2);
    cursor: pointer;

    &:hover {
      background: var(--tertiary);
      color: var(--white);
    }
  }

  .badge.active {
    background: var(--tertiary);
    color: var(--white);
  }
}</style>