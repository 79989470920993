<template>
  <div id="app">
    <transition mode="out-in">
      <router-view />
    </transition>
    <!--<checkout />-->
        <div class="block-ui" v-show="$store.state.pagamentoLoading" style="z-index: 9999">
      <div>
        <i class="material-icons text-white text-center d-flex justify-content-center cloud">sentiment_satisfied_alt</i>
        <h1 class="text-white text-center">Aguarde, processando seu pagamento.</h1>
        <loading color="#fff" />
      </div>
    </div>
  </div>
</template>
<script>
//import Checkout from "./views/Curso/Checkout.vue";
export default {
  components: {
    //Checkout,
  },
  data() {
      return {
        google: '',
      }
    },
  computed: {
    storeCart() {
      return this.$store.getters.getCart;
    },
  },
  methods: {
    loadGoogleAnalytics() {
      // Crie um elemento de script
      var script = document.createElement('script');

      // Defina o atributo src para o URL do script do Google Analytics
      script.src = 'https://www.googletagmanager.com/gtag/js?id='+this.google;

      // Adicione o script ao final do corpo da página
      document.body.appendChild(script);

      // Adicione a função de configuração do Google Analytics
      script.onload = function () {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          dataLayer.push(arguments);
        }
        gtag('js', new Date());
        gtag('config', this.google);
      };
    }
  },
  created() {
    /*
    this.$http
        .get("/api/user/script")
        .then((response) => {
          if(response.data.script.length > 0){
            // Crie um novo elemento script
            const newScript = document.createElement('script');
            // Defina o código que você deseja inserir no novo script
            newScript.innerHTML = response.data.script;
            // Adicione o novo script ao head do documento
            document.head.appendChild(newScript);
          }
          if(response.data.tag.length > 0){
            document.head.innerHTML = document.head.innerHTML + response.data.tag;
          }
          if(response.data.body.length > 0){
            // Crie um novo elemento script
            const newbody = document.createElement('div');
            // Defina o código que você deseja inserir no novo script
            newbody.innerHTML = response.data.body;
            // Adicione o novo script ao head do documento
            document.body.appendChild(newbody);
          }
          if(response.data.google.length > 0){
            this.google = response.data.google;
          }
        })
        .catch(() => {});
*/

    if(window.localStorage.carrinho){
       this.$store.commit("UPDATE_CART_SESSION", JSON.parse(window.localStorage.carrinho));
    }
    if (sessionStorage.token) { 
      this.$store.commit("UPDATE_USER", JSON.parse(sessionStorage.userData));

        this.$http
          .validate()
          .then(response => {
         sessionStorage.setItem('userData', JSON.stringify(response.data))
            this.$store.commit(
              "UPDATE_USER",
              JSON.parse(sessionStorage.userData)
            );
          })
          .catch(() => {
            this.$store.dispatch("LOGOUT");
            this.$router.push({ name: "login" });
          });
 
    }
  },
  mounted() {
    /*
    if(this.google > 0){
      setTimeout(function () {
        this.loadGoogleAnalytics;
      }, 5000);
    }
    */
  },
};
</script>
<style lang="scss">
.VueCarousel-pagination{
    display:none;
}
</style>
